// const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;
const PATH_PREFIX = '';
export const Routing = {
    UnitConverter: `/`,
    UnitConverters: `/:moduleId?`,
    CmToInches: `/length/cm-to-inches`,
    InchesToCm: `/length/inches-to-cm`,

    MmToInches: `/length/mm-to-inches`,
    InchesToMm: `/length/inches-to-mm`,

    MeterToFeet: `/length/meter-to-feets`,
    FeetToMeter: `/length/feets-to-meter`,

    KmToMiles: `/length/km-to-miles`,
    MilesToKm: `/length/miles-to-km`,

    CmToFeets: `/length/cm-to-feets`,
    FeetsToCm: `/length/feets-to-cm`,
    
    KgToLbs: `/mass/kg-to-lbs`,
    LbsToKm: `/mass/lbs-to-kg`
}
