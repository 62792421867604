import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { Routing } from "./constants/routing";

/* Import lazy routes */
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const UnitConverter = lazy(
  () => import("../pages/UnitConverter/UnitConverter")
);
const CmToInches = lazy(
  () => import("../pages/LengthConverters/cm-inch/CmToInches")
);
const InchesToCm = lazy(
  () => import("../pages/LengthConverters/cm-inch/InchesToCm")
);
const MmToInches = lazy(
  () => import("../pages/LengthConverters/mm-inch/MmToInches")
);
const InchesToMm = lazy(
  () => import("../pages/LengthConverters/mm-inch/InchesToMm")
);
const MeterToFeet = lazy(
  () => import("../pages/LengthConverters/meter-feet/MeterToFeet")
);
const FeetToMeter = lazy(
  () => import("../pages/LengthConverters/meter-feet/FeetToMeter")
);

const KmToMiles = lazy(
  () => import("../pages/LengthConverters/km-miles/KmToMiles")
);

const MilesToKm = lazy(
  () => import("../pages/LengthConverters/km-miles/MilesToKm")
);

const CmToFeets = lazy(
  () => import("../pages/LengthConverters/cm-feets/CmToFeets")
);
const FeetsToCm = lazy(
  () => import("../pages/LengthConverters/cm-feets/FeetsToCm")
);

const KgToLbs = lazy(() => import("../pages/MassConverters/kg-lbs/KgToLbs"));
const LbsToKm = lazy(() => import("../pages/MassConverters/kg-lbs/LbsToKg"));

interface IRoute {
  path: string;
  component: any;
  isPrivateRoute: boolean;
}

const routes: IRoute[] = [
  {
    path: Routing.UnitConverter,
    component: <UnitConverter />,
    isPrivateRoute: false,
  },
  {
    path: Routing.UnitConverters,
    component: <UnitConverter />,
    isPrivateRoute: false,
  },
  {
    path: Routing.CmToInches,
    component: <CmToInches />,
    isPrivateRoute: false,
  },
  {
    path: Routing.InchesToCm,
    component: <InchesToCm />,
    isPrivateRoute: false,
  },
  {
    path: Routing.MmToInches,
    component: <MmToInches />,
    isPrivateRoute: false,
  },
  {
    path: Routing.InchesToMm,
    component: <InchesToMm />,
    isPrivateRoute: false,
  },
  {
    path: Routing.MeterToFeet,
    component: <MeterToFeet />,
    isPrivateRoute: false,
  },
  {
    path: Routing.FeetToMeter,
    component: <FeetToMeter />,
    isPrivateRoute: false,
  },
  {
    path: Routing.KmToMiles,
    component: <KmToMiles />,
    isPrivateRoute: false,
  },
  {
    path: Routing.MilesToKm,
    component: <MilesToKm />,
    isPrivateRoute: false,
  },
  {
    path: Routing.CmToFeets,
    component: <CmToFeets />,
    isPrivateRoute: false,
  },
  {
    path: Routing.FeetsToCm,
    component: <FeetsToCm />,
    isPrivateRoute: false,
  },
  {
    path: Routing.KgToLbs,
    component: <KgToLbs />,
    isPrivateRoute: false,
  },
  {
    path: Routing.LbsToKm,
    component: <LbsToKm />,
    isPrivateRoute: false,
  },
];

export const AppRouting: React.FC = () => {
  return (
    <Router>
      <MainLayout>
        <Switch>
          {routes
            .filter((route) => !route.isPrivateRoute)
            .map(({ path, component }, key) => (
              <Route exact path={path} component={() => component} key={key} />
            ))}
          <Redirect to={Routing.UnitConverter} />
        </Switch>
      </MainLayout>
    </Router>
  );
};
