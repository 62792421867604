import { createTheme } from '@mui/material/styles';

let portalTheme = createTheme({
    palette: {
        primary: {
            main: '#336699',
            dark: '#9ABBE0',
            light: '#345177',
        },
        // for table
        secondary: {
            contrastText: '#F2F5F7',
            main: '#A2A2A2',
            light: '#808080',
            dark: '#5D6679',
        },
        text: {
            primary: '#000000',
            disabled: '#000000',
            secondary: '#D0D3D9',
        },
        info: {
            main: '#f6f6f6',
            contrastText: '#E14F4F',
            light: '#FFFF',
        },
        error: {
            main: '#FF5353',
            light: '#FF8A8A',
        },
        warning: {
            main: '#F8824F',
            dark: '#000000',
            light: '#383E49',
        },
        success: {
            main: '#29B18C',
            // main: '#45D09E',
        },
    },
    typography: {
        fontFamily: 'Poppins,sans-serif !important',
    },
});

portalTheme = createTheme(portalTheme, {
    // Typography
    typography: {
        h1: {
            fontSize: '1.33333333333rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
        },
        h2: {
            fontSize: '17px',
            lineHeight: '30px',
            fontWeight: 500,
            letterSpacing: 0,
            color: [portalTheme.palette.warning.light],
            [portalTheme.breakpoints.up('md')]: { fontSize: '20px' },
        },
        h3: {
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 600,
            letterSpacing: 0,
            color: [portalTheme.palette.secondary.main],
            // [portalTheme.breakpoints.up("md")]: { fontSize: "16px" },
        },
        h4: {
            fontSize: '25px',
            lineHeight: '30px',
            fontWeight: 500,
            letterSpacing: 0,
            color: [portalTheme.palette.secondary.main],
            [portalTheme.breakpoints.down("sm")]: { fontSize: "20px" },
        },
        h5: {
            fontSize: '1.33333333333rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
        },
        h6: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 500,
            letterSpacing: 0,
            [portalTheme.breakpoints.down("sm")]: { fontSize: "16px", lineHeight: '20px'},
        },
        body1: {
            fontSize: 13,
            lineHeight: 1.4,
            fontWeight: 400,
            letterSpacing: 0,
            color: [portalTheme.palette.secondary.light],
            fontFamily:'Poppins,sans-serif',
            [portalTheme.breakpoints.up("md")]: { fontSize: "14px" },

        },
        body2: {
            fontSize: 13,
            lineHeight: 1.2,
            fontWeight: 600,
            letterSpacing: 0,
            [portalTheme.breakpoints.up("md")]: { fontSize: "14px" },

        },
        caption: {
            fontSize: '0.8rem',
            lineHeight: 1.06666667,
            fontWeight: 400,
            letterSpacing: 0,
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: [portalTheme.palette.text.disabled],
                    transformOrigin: 'left center',
                    maxWidth: 'calc(100% - 44px)',
                },
                // shrink: {
                //   fontSize: 15,
                // },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    input: {
                        padding: 13,
                        height: 24,
                        color: [portalTheme.palette.warning.dark],
                    },
                    '&:hover': {
                        fieldset: {
                            borderColor: [portalTheme.palette.text.disabled] + '!important',
                        },
                    },
                },
                notchedOutline: {
                    borderColor: [portalTheme.palette.text.secondary],
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                adornedEnd: {
                    paddingRight: '30px!important',
                },
            },
        },

        // MuiFormControlLabel: {
        //   styleOverrides: {
        //     root: {
        //       marginLeft: -8,
        //     },
        //   },
        // },

        MuiPaper: {
            styleOverrides: {
                root: {
                    // boxShadow: '0px 0px 10px 1px #CBE0EC',
                    borderRadius: 5,
                    boxShadow:
                        '0px 1px 4px -1px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                },
            },
        },

        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: '1px solid #4087FC!important',
                    },
                    '&:hover': {
                        '&:before': {
                            borderBottomColor: [portalTheme.palette.primary.main] + '!important',
                        },
                    },
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },

        // // Buttons
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontSize: '19px',
                    lineHeight: '18px',
                    fontWeight: 400,
                    padding: '15px 25px',
                    boxShadow: 'none!important',
                    border: '1px solid' + portalTheme.palette.primary.main,
                },
                containedSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                containedSizeMedium: {
                    fontSize: 16,
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                containedSizeLarge: {
                    fontSize: 16,
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                textSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                textSizeMedium: {
                    fontSize: 16,
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                textSizeLarge: {
                    fontSize: 16,
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                outlinedSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 5,
                    paddingBottom: 5,
                },
                outlinedSizeMedium: {
                    fontSize: 16,
                    paddingTop: 9,
                    paddingBottom: 9,
                },
                outlinedSizeLarge: {
                    fontSize: 16,
                    paddingTop: 14,
                    paddingBottom: 14,
                },

                startIcon: {
                    marginRight: 6,
                    marginLeft: -2,
                },
                containedPrimary: {
                    borderColor: [portalTheme.palette.primary.main],
                    '&:hover': {
                        backgroundColor: '#143b62 !important',
                        border: '1px solid #143b62',
                    },
                },
                outlinedPrimary: {
                    borderColor: [portalTheme.palette.primary.main],
                    '&:hover': {
                        backgroundColor: '#d2dbe7 !important',
                        border: '1px solid ##d2dbe7',
                    },
                },
            },
        },

        MuiMenuItem: {
          styleOverrides: {
            root: {
                  minHeight: "auto",
                  color:portalTheme.palette.warning.dark
            },
          },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    opacity: 1,
                    fill: [portalTheme.palette.primary.main],
                },
                root: {
                    color: [portalTheme.palette.primary.main] + '!important',
                    '&.Mui-active .MuiTableSortLabel-icon': {
                        fill: [portalTheme.palette.primary.main] + '!important',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    height: 27,
                    fontSize: 13,
                    lineHeight: '15px',
                    boxShadow: 'none!important',
                    '&.rounded': {
                        borderRadius: 24,
                    },
                },
                sizeMedium: {
                    borderRadius: 24,
                    minHeight: 30,
                    fontWeight: 400,
                },
                deleteIcon: {
                    margin: '0 9px 0 -3px',
                },
                labelMedium: {
                    fontSize: 12,
                    lineHeight: 1.2,
                    padding: '6px 6px 6px 9px',
                },
                label: {
                    padding: '4px 6px',
                    fontWeight: 600,
                },
                colorPrimary: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                colorInfo: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                colorSuccess: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                colorWarning: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                filled: {
                    '&.MuiChip-filledInfo': {
                        backgroundColor: [portalTheme.palette.info.contrastText] + '!important',
                    },
                    '&.MuiChip-filledSuccess': {
                        backgroundColor: [portalTheme.palette.success.main] + '!important',
                    },
                    '&.MuiChip-filledError': {
                        backgroundColor: [portalTheme.palette.error.light] + '!important',
                    },
                    '&.MuiChip-filledSecondary': {
                        backgroundColor: [portalTheme.palette.secondary.dark] + '!important',
                    },
                    '&.MuiChip-filledPrimary': {
                        backgroundColor: [portalTheme.palette.primary.main] + '!important',
                        color: [portalTheme.palette.secondary.contrastText] + '!important',
                        '& .MuiChip-deleteIconOutlinedColorPrimary': {
                            color: [portalTheme.palette.secondary.contrastText] + '!important',
                        },
                    },
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
                outlined: {
                    '&.MuiChip-outlinedPrimary': {
                        borderColor: '#2B66AA',
                        color: '#2B66AA',
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: [portalTheme.palette.primary.main] + '!important',
                            color: [portalTheme.palette.secondary.contrastText] + '!important',
                            '& .MuiChip-deleteIconOutlinedColorPrimary': {
                                color: [portalTheme.palette.secondary.contrastText] + '!important',
                            },
                        },
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    border: '1px solid rgb(209, 209, 209)',
                    borderRadius: 8,
                    marginBottom: 4,
                },
            },
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgb(209, 209, 209)',
                    borderRadius:'8px 8px 0 0',
                    borderColor: 'rgb(209, 209, 209)',
                    '& .MuiCheckbox-root': {
                        marginLeft: -8,
                        marginTop: -8,
                        marginBottom: -8,
                    },
                },
                head: {
                    padding: '17px 10px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 600,
                    backgroundColor: [portalTheme.palette.info.light],
                    color: [portalTheme.palette.primary.main],
                    whiteSpace: 'nowrap',
                    [portalTheme.breakpoints.up('md')]: { fontSize: '16px' },
                },
                body: {
                    padding: '9px 10px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    color: '#666666',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    [portalTheme.breakpoints.up('md')]: { fontSize: '16px' },

                },
            },
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 151, 169, 0.2)',
                    },
                    '&:last-child': {
                        '& .MuiTableCell-body': {
                            borderBottom: 0,
                            borderRadius:'0 0 8px 8px',
                        },
                    },
                    '&:nth-of-type(odd)': {
                        backgroundColor: 'rgb(240, 240, 240)', 
                    },
                },
            },
        },

        MuiPaginationItem: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 15,
                    lineHeight: '18px',
                    color: [portalTheme.palette.warning.light],
                    padding: '6px',
                    height: 28,
                    minWidth: 28,
                    margin: '0 4px',
                    '&.Mui-selected': {
                        color: [portalTheme.palette.info.light],
                        backgroundColor: [portalTheme.palette.primary.main],
                        '&:hover': {
                            backgroundColor: '#275c4d',
                        },
                    },
                    '& .MuiPaginationItem-icon': {
                        width: 22,
                        height: 22,
                    },
                },
            },
        },
        // //

        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: [portalTheme.palette.primary.light],
                    borderRadius: 5,
                    margin: 15,
                    [portalTheme.breakpoints.up('md')]: {
                        margin: 40,
                    },
                },
                paperWidthSm: {
                    maxWidth: 420,
                    width: 420,
                },
                paperWidthMd: {
                    maxWidth: 600,
                    width: 600,
                },
                paperWidthLg: {
                    maxWidth: 1056,
                    width: 1056,
                },
                paperWidthXl: {
                    maxWidth: 1400,
                    width: 1400,
                },
            },
        },

        // Dialog
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px 20px 0!important',
                    minHeight: 40,
                    backgroundColor: '#FFFF',
                    '&.no-action': {
                        padding: '25px!important',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '35px!important',
                        },
                    },
                    '&.form-dialog-content': {
                        padding: '20px 20px 16px!important',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '20px!important',
                        },
                    },
                },
            },
        },

        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '16px 20px!important',
                    backgroundColor: '#FFFF',
                    flexWrap: 'wrap',
                    '&>:first-of-type': {
                        margin: '4px 0',
                    },
                    '&>:not(:first-of-type)': {
                        margin: '4px 0',
                        marginLeft: 8,
                        [portalTheme.breakpoints.up('sm')]: {
                            marginLeft: 20,
                        },
                    },
                    '&.manage-patient': {
                        padding: '20px 0!important',
                    },
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: '20px!important',
                        flexWrap: 'normal',
                    },
                    '&.form-dialog-action': {
                        padding: '16px 0 0!important',
                        flexWrap: 'wrap',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '20px 0 0!important',
                            flexWrap: 'normal',
                        },
                    },
                },
            },
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 16px',
                    backgroundColor: [portalTheme.palette.primary.main],
                    color: [portalTheme.palette.info.light],
                    '& .MuiTypography-root': {
                        fontSize: '1.2rem',
                        lineHeight: 1.3333333,
                    },
                    '& .close-action': {
                        minWidth: 24,
                        margin: '-4px -4px -4px 0',
                    },
                },
            },
        },
        // //select
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 13,
                    paddingBottom: 13,
                    paddingLeft: 13,
                    height: 24,
                    textAlign: 'left',
                    // color: [portalTheme.palette.text.primary],
                },
                icon: {
                    width: 5,
                    height: 5,
                    borderBottom: 'solid 2px #000000',
                    borderRight: 'solid 2px #000000',
                    transform: 'rotate(45deg)',
                    right: 13,
                    top: 'calc(50% - 4px)',
                    path: {
                        opacity: 0,
                    },
                },
            },
        },
        //Tabs
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '10px 30px',
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: 15,
                    fontWeight: '500',
                    lineHeight: '18px',
                    minHeight: 'auto',
                    color: '#8A8888',
                    [portalTheme.breakpoints.up('md')]: {
                        fontSize: 18,
                        // width: "100%",
                        // marginTop: "25px",
                    },
                },
            },
        },
    },
});

export default portalTheme;
